import React, { Component, useEffect } from "react";
// import Purchase from "../components/Purchase";
import { withFirebase } from "../HOC/withFirebase";
import fire from "../fire";

function PaySuccess({ context }) {
  const { firebase }: { firebase: typeof fire } = context;

  useEffect(() => {
    let orders = firebase.firestore().collection("Orders");
    let observer = orders.onSnapshot(
      (docSnapshot) => {
        console.log(docSnapshot.docs[0].data());
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  }, [firebase]);
  // async componentDidMount() {
  //     this.setState({lodaingProfile: true });
  //     try {
  //       db.ref("paid/"+this.state.user.uid).on("value", snapshot => {
  //         let paid = false;
  //         try{paid = snapshot.val().paid;}catch(error){db.ref("paid/"+this.state.user.uid).set({
  //           paid: false,
  //           timestamp: Date.now(),
  //         });}
  //         this.setState({ paid });
  //         this.setState({ lodaingProfile: false });
  //     });
  //     } catch (error) {
  //       this.setState({ lodaingProfile: false });
  //     }
  //   }

  return (
    <div>
      {/* {this.state.loadingProfile ? (
        <div className="spinner-border text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        ""
      )}

      <Purchase paid={this.state.paid} />

      <div className="py-2 mx-3">
        Login in as:{" "}
        <strong className="text-info">{this.state.user.email}</strong>
      </div> */}
    </div>
  );
}

export default withFirebase(PaySuccess);
