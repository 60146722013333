import React from "react";
import Promo from "../Promo";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router";
import * as moisturizer from "../../assets/moisturizer.jpg";

function Infos() {
  const history = useHistory();

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div className="about-text">
              <h2 style={{ color: "white", fontWeight: "bolder" }}>
                Digital Gift Cards Software That Make the Hardest Times Painless
              </h2>
              <p style={{ color: "white", fontSize: 24, fontWeight: "bold" }}>
                The all-new green coupon is a software that makes running your
                business in a creative, easy and secure way, whatever situation
                you’re in!
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: 24,
                  marginTop: 24,
                  fontWeight: "bold",
                }}
              >
                Sell your services to your customers even if your store is
                closed, and get ready to receive them in the future and do the
                work you love.
              </p>
              <Button
                style={{
                  width: "40%",
                  background: "white",
                  color: "#5ca499",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                type="submit"
                secondary
                onClick={() => history.push("Register")}
              >
                Get started
              </Button>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <Promo
              promo={{
                image: moisturizer,
                promoName: "Spa Detox Package",
                initialPrice: 350,
                discount: 20,
                description:
                  "An Aromatic and Uplifting Spa Experience. \n Limited Time Offer! Get a 20% Off on this Spa Detox Package. Buy Now and Use it Later! \n Valid from 1st June 2020 until 1st June 2021.",
              }}
              fake
              buy={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Infos;
