import React, { Component } from "react";
import { Button, Image } from "semantic-ui-react";
import * as logo from "../../assets/logo.png";
import { useHistory } from "react-router";

function Signup() {
  const history = useHistory();

  return (
    <div style={{ backgroundColor: "#5ca499", padding: "100px 0px" }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12" style={{ textAlign: "center" }}>
            <h2 style={{ color: "white" }}>
              Tough times never last, but tough businesses do.
            </h2>
          </div>

          <div className="col-xs-12 col-md-12" style={{ textAlign: "center" }}>
            <h3
              style={{
                textAlign: "center",
                marginTop: 24,
                marginBottom: 24,
                color: "white",
              }}
            >
              Join the List of Hundreds of Happy Businesses Owners from All Over
              the World
            </h3>
            <Button
              type="submit"
              style={{
                width: "40%",
                background: "white",
                color: "#5ca499",
                fontSize: 16,
                fontWeight: "bold",
              }}
              secondary
              onClick={() => history.push("Register")}
            >
              Sign up now
            </Button>
          </div>
        </div>
        {/* <Image
            src={logo}
            style={{ width: "auto", height: 30, marginTop: "auto" }}
            size={"medium"}
          /> */}
      </div>
    </div>
  );
}

export default Signup;
