import React from "react";
import { Image, Button } from "semantic-ui-react";

export default function Company({ company }: any) {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 16,
        marginTop: 24,

        width: 350,
      }}
    >
      <Image
        style={{
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          height: 300,
        }}
        src={
          company.image ||
          "https://react.semantic-ui.com/images/wireframe/image.png"
        }
        size="large"
      />
      <div style={{ padding: 16 }}>
        <p>{company.businessName}</p>
        <p>{company.businessAddress.name}</p>
        <p>{company.businessAddress.address}</p>
        <p>{company.mobileNumber}</p>

        {/* <Button fluid type="submit">
          Buy now
        </Button> */}
      </div>
    </div>
  );
}
