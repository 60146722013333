import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Button, Form, Input, Label, TextArea } from "semantic-ui-react";
import axios from "axios";
export default function Contact() {
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [text, setText] = useState("");
  const [sent, setSent] = useState(false);

  async function sendEmail() {
    if (email && number && text) {
      // const response = await axios.post(
      // "https://us-central1-lockdowncoupons.cloudfunctions.net/sendMail",
      //   JSON.stringify({ email, number, text })
      // );
      fetch("https://us-central1-lockdowncoupons.cloudfunctions.net/sendMail", {
        method: "POST",
        // Adding the order data to payload
        body: JSON.stringify({ email, number, text }),
      }).then((response) => {
        setEmail("");
        setNumber("");
        setText("");
        setSent(true);
      });
    }
  }

  return (
    <div style={{ background: "white", padding: 32 }} id="#contact">
      <div className="col-xs-12 col-md-12" style={{ textAlign: "center" }}>
        <h2 style={{ color: "black", marginBottom: 48 }}>
          Get in touch with Us
        </h2>
      </div>
      <Form
        style={{
          width: 500,
          marginLeft: "auto",
          marginRight: "auto",
          alignSelf: "center",
        }}
      >
        <Form.Field>
          <label style={{ color: "black" }}>Email*</label>
          <input
            required
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Form.Field>
        <Form.Field>
          <label style={{ color: "black" }}>Mobile Number</label>
          <Input labelPosition="left" type="text" placeholder="Discount">
            <Label basic>+65</Label>
            <input
              placeholder="Mobile Number"
              maxLength={8}
              value={number}
              onChange={(e) => {
                setNumber(e.target.value.replace(/\D/, ""));
              }}
            />
          </Input>
        </Form.Field>
        <Form.Field>
          <textarea
            rows={2}
            placeholder="Your message here."
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </Form.Field>
        {sent ? (
          <h3 style={{ color: "black", textAlign: "center" }}>
            Thank you for contacting us, we'll respond in less than 24 hours
          </h3>
        ) : (
          <Button fluid secondary type="submit" onClick={() => sendEmail()}>
            Submit
          </Button>
        )}
      </Form>
    </div>
  );
}
