import React, { useState, useEffect } from "react";
import fire from "../fire";
import * as logo from "../assets/logo.png";

import Promo from "../components/Promo";

import withUser from "../HOC/withUser";
import { withAuthentication } from "../HOC/withFirebase";
import { Card, Header, Image } from "semantic-ui-react";
import WhyUs from "../components/WhyUs";

function BusinessDeals({ context, match }) {
  const { user } = context;
  const { business } = match.params;
  const [promos, setPromos] = useState<Array<any>>([]);
  const [company, setCompany] = useState<any>("");

  useEffect(() => {
    fire
      .firestore()
      .collection("Companies")
      .where("businessName", "==", business)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setCompany(doc.data());
          console.log(doc.ref.id);
          fire
            .firestore()
            .collection("Promos")
            .where("companyID", "==", doc.ref.id)
            .where("isDeleted", "==", false)
            .get()
            .then(function (querySnapshot) {
              return querySnapshot.docs.map((doc) => {
                setPromos((prevPromos: any) => prevPromos.concat(doc.data()));
              });
            });
        });
      });
  }, [business]);

  return (
    <div
      style={{
        padding: 48,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        background: "white",
      }}
    >
      {company && (
        <div
          style={{
            flexDirection: "row",
            alignSelf: "center",
            color: "white",
            textAlign: "center",
          }}
        >
          <Image
            src={
              company.image ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcT1TTyVK8qrvafj1y_oOWatbRZQigrdaeKOm0pHjIqcdbM8uT0s&usqp=CAU"
            }
            // width="%"
            height={400}
            style={{ marginBottom: 24 }}
          />
          <h3 style={{ color: "black" }}>{company.businessAddress.name}</h3>
          <h4 style={{ color: "black" }}>{company.businessAddress.address}</h4>
        </div>
      )}
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          marginTop: 48,
        }}
      >
        <Header style={{ color: "black" }} size={"huge"}>
          {" "}
          Our Offers
        </Header>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {promos.map((promo, index) => {
            return (
              <>
                <Promo promo={promo} key={index} buy />
              </>
            );
          })}
        </div>
      </div>
      <Image
        src={logo}
        style={{
          height: 40,
          width: "auto",
          marginTop: 48,
          // marginTop: "auto",
          alignSelf: "center",
        }}
        size={"medium"}
      />
    </div>
  );
}

export default withAuthentication(BusinessDeals);
