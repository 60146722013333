import React, { useState } from "react";
import { Image, Button, Header } from "semantic-ui-react";
import { withAuthentication } from "../HOC/withFirebase";
import fire from "../fire";

function checkout(orderData: any) {
  //@ts-ignore
  const stripe = window.Stripe("pk_test_4F0ALQBb9oPCqUjywHbh2WUS00U8Wvils2");
  let sessionId;
  // Url to Firebase function
  fetch(
    "https://us-central1-lockdowncoupons.cloudfunctions.net/createOrderAndSession",
    {
      method: "POST",
      // Adding the order data to payload
      body: JSON.stringify(orderData),
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      // Getting the session id from firebase function
      var body = JSON.parse(data.body);
      return (sessionId = body.sessionId);
    })
    .then((sessionId) => {
      // Redirecting to payment form page
      stripe
        .redirectToCheckout({
          sessionId: sessionId,
        })
        .then(function (result: any) {
          console.log(result);
          // result.error.message;
        });
    });
}

function Promo({ promo, buy = false, fake = false, remove = false }: any) {
  const [isHidden, setIsHidden] = useState(false);
  const price =
    promo.initialPrice - (promo.initialPrice * promo.discount) / 100;
  let orderData = {
    currency: "SGD",
    quantity: 1,
    amount: price * 100,
    name: promo.promoName,
    description: "Puchased via GreenCoupon",
    image: promo.image,
  };
  return (
    <div
      style={{
        display: isHidden ? "none" : "block",
        backgroundColor: "white",
        borderRadius: 16,
        marginLeft: 16,
        marginRight: 16,
        marginTop: 24,
        boxShadow: "-2px 4px 5px 0px rgba(112,112,112,1)",
        width: 400,
        height: 500,
      }}
    >
      <Image
        label={
          promo.discount > 0 && {
            as: "a",
            color: "red",
            content: `- ${promo.discount} % OFF !`,
            icon: "tag",
            ribbon: true,
          }
        }
        style={{
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          overflow: "hidden",
          height: "50%",
          width: "100%",
          objectFit: "cover",
        }}
        src={
          promo.image ||
          "https://react.semantic-ui.com/images/wireframe/image.png"
        }
        size="large"
      />
      <div
        style={{
          padding: 16,
          display: "flex",
          height: "50%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Header style={{ textAlign: "center", margin: 0 }}>
            {promo.promoName}
          </Header>
          {promo.initialPrice > 0 && (
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
              }}
            >
              {promo.discount > 0 && (
                <span
                  style={{
                    textDecoration: "line-through",
                    fontWeight: "normal",
                    marginRight: 6,
                  }}
                >
                  {promo.initialPrice} SGD{"     "}
                </span>
              )}
              {"     "}
              <span style={{ fontWeight: "bold" }}>
                {promo.initialPrice -
                  (promo.initialPrice * promo.discount) / 100}{" "}
                SGD
              </span>
            </div>
          )}
        </div>
        <p>{promo.description}</p>

        {buy && (
          <Button
            fluid
            type="submit"
            secondary
            onClick={() => !fake && checkout(orderData)}
          >
            Buy now
          </Button>
        )}
        {remove && (
          <Button
            fluid
            type="submit"
            color="red"
            onClick={() =>
              fire
                .firestore()
                .collection("Promos")
                .doc(promo.id)
                .update({ isDeleted: true })
                .then(() => setIsHidden(true))
            }
          >
            Cancel promo
          </Button>
        )}
      </div>
    </div>
  );
}

export default withAuthentication(Promo);
