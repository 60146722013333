import React from "react";
import { Header, Button, Image } from "semantic-ui-react";
import * as logo from "../assets/logo.png";
import { useHistory } from "react-router";

export default function NoMatch() {
  const history = useHistory();
  return (
    <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Image
          src={logo}
          style={{ width: "auto", height: 30, marginBottom: 80 }}
          size={"medium"}
        />
        <Header style={{ fontSize: 72, color: "white" }}>
          Sorry, this page is not available
        </Header>
        <Header style={{ color: "white", fontSize: 28, marginTop: 0 }}>
          We can't find the page you are looking for.
        </Header>
        <Button onClick={() => history.push("/")}>
          Return to the home page.
        </Button>
      </div>
    </div>
  );
}
