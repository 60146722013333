import React, { useState, useEffect } from "react";
import fire from "../fire";
import Promo from "../components/Promo";
import HeaderBar from "../components/HeaderBar";
import withUser from "../HOC/withUser";
import { withAuthentication } from "../HOC/withFirebase";
import Company from "../components/Company";

function Companies({ context }) {
  const { user } = context;
  const [companies, setCompanies] = useState<Array<any>>([]);

  useEffect(() => {
    fire
      .firestore()
      .collection("Companies")
      .get()
      .then(function (querySnapshot) {
        return querySnapshot.docs.map((doc) => {
          setCompanies((prevCompanies: any) =>
            prevCompanies.concat(doc.data())
          );
        });
      });
  }, []);

  return (
    <div style={{ flex: 1 }}>
      <HeaderBar />
      <div
        style={{
          padding: 48,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {companies.map((company, index) => {
          return <Company company={company} key={index} />;
        })}
      </div>
    </div>
  );
}

export default withAuthentication(Companies);
