import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { withAuthentication, withFirebase } from "./HOC/withFirebase";
import Login from "./screens/Login";

class CustomRoute extends React.Component<any> {
  state = {
    returnedRoute: null,
  };

  getRoute() {
    const { condition, context, path, component } = this.props;
    const { claims, user, loaded } = context;
    if (loaded) {
      if (user) {
        switch (condition) {
          case "admin":
            return this.setState({
              returnedRoute:
                claims && claims.isAdmin ? (
                  <Route {...this.props} />
                ) : (
                  <Redirect to="/" />
                ),
            });
          case "logged":
            console.log(claims);
            if (path === "/") {
              return this.setState({
                returnedRoute: claims ? (
                  <Route {...this.props} />
                ) : (
                  <Redirect to="/" />
                ),
              });
            }
            return this.setState({
              returnedRoute: claims ? (
                <Route path={path} component={component} />
              ) : (
                <Redirect to="/" />
              ),
            });
          //   case "signedIn":
          //     return setReturnedRoute(
          //       user.isSignedIn ? <Route {...props} /> : <Redirect to="/" />
          //     );
          default:
            return this.setState({ returnedRoute: <Redirect to="/Login" /> });
        }
      } else {
        console.log("Here");
        return this.setState({
          returnedRoute: <Redirect to="/Login" />,
        });
      }
    }
  }
  componentDidMount() {
    this.getRoute();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.context !== prevProps.context) {
      this.getRoute();
    }
    if (this.props.path !== prevProps.path) {
      this.getRoute();
    }
  }

  render() {
    return <> {this.state.returnedRoute}</>;
  }
}

// const CustomRoute = (props) => {
//   const { context, condition, component, path } = props;
//   const [returnedRoute, setReturnedRoute] = useState<any>("");
//   const { user } = context;
//   user.admin = true;
//   console.log(user);
//   useEffect(() => {

//   }, [component, condition, path, user]);
// };

export default withFirebase(CustomRoute);
