import React, { useState, useEffect } from "react";
import fire from "../fire";
import HeaderBar from "../components/HeaderBar";
import withUser from "../HOC/withUser";
import { withAuthentication } from "../HOC/withFirebase";

function Orders({ context }) {
  const { user } = context;
  const [orders, setOrders] = useState<Array<any>>([]);

  useEffect(() => {
    fire
      .firestore()
      .collection("Orders")
      .get()
      .then(function (querySnapshot) {
        return querySnapshot.docs.map((doc) => {
          setOrders((prevOrders: any) => prevOrders.concat(doc.data()));
        });
      });
  }, []);

  return (
    <div style={{ flex: 1 }}>
      <HeaderBar />
      <div
        style={{
          padding: 48,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: 48, color: "white" }}>You have no orders yet.</p>
      </div>
    </div>
  );
}

export default withAuthentication(Orders);
