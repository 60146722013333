import firebase from "firebase";
import "firebase/storage";

const fconfig = {
  apiKey: "AIzaSyCMku0mcVk1Z2UVmDF7jLRkuiE42Cdt9cM",
  authDomain: "lockdowncoupons.firebaseapp.com",
  databaseURL: "https://lockdowncoupons.firebaseio.com",
  projectId: "lockdowncoupons",
  storageBucket: "lockdowncoupons.appspot.com",
  messagingSenderId: "860308164163",
  appId: "1:860308164163:web:fd3fe1c518ab831e0ecef6",
  measurementId: "G-XRNDW28RD1",
};
var fire = firebase.initializeApp(fconfig);

const storage = firebase.storage();

export { storage, fire as default };
