import React from "react";
import { Route, Router, Switch } from "react-router";

import { createBrowserHistory } from "history";
import Register from "./screens/Register";
import AddPromo from "./screens/AddPromo";
import Main from "./screens/Main";
import Login from "./screens/Login";
import AllPromos from "./screens/AllPromos";
import AddCompany from "./screens/AddCompany";
import { FirebaseProvider, withFirebase } from "./HOC/withFirebase";
import Companies from "./screens/Companies";
import CustomRoute from "./CustomRoute";
import NoMatch from "./screens/NoMatch";
import BusinessDeals from "./screens/BusinessDeals";
import PaySuccess from "./screens/PaySuccess";
import Orders from "./screens/Orders";
import MyPromos from "./screens/MyPromos";

function App() {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <FirebaseProvider context>
        <Switch>
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/Deals/:business" component={BusinessDeals} />
          <Route exact path="/successPay" component={PaySuccess} />

          <Route exact path="/" component={Main} />
          <CustomRoute
            exact
            path="/orders"
            condition="logged"
            component={Orders}
          />
          <CustomRoute
            exact
            path="/addpromo"
            condition="logged"
            component={AddPromo}
          />
          <CustomRoute
            exact
            condition="logged"
            path="/AddCompany"
            component={AddCompany}
          />

          <CustomRoute
            condition="admin"
            exact
            path="/AllPromos"
            component={AllPromos}
          />
          <CustomRoute
            condition="logged"
            exact
            path="/MyPromos"
            component={MyPromos}
          />
          <CustomRoute
            exact
            condition="admin"
            path="/companies"
            component={Companies}
          />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </FirebaseProvider>
    </Router>
  );
}

export default withFirebase(App);
