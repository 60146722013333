import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Label,
  Header,
} from "semantic-ui-react";
//@ts-ignore
import Autocomplete from "react-google-autocomplete";

import fire from "../fire";
import HeaderBar from "../components/HeaderBar";
import Category from "../components/Category";
import { withAuthentication } from "../HOC/withFirebase";

const categories = require("../assets/categories.json");

function AddCompany({ context }) {
  const [mobileNumber, setMobileNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState({});
  const [businessCategory, setBusinessCategory] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);

  const { claims } = context;

  function onClickAction(business: any) {
    setButtonStatus(true);

    const userRef = fire.firestore().collection("Companies").doc();

    userRef.set(business).then((done) => {
      setBusinessName("");
      setBusinessCategory("");
      setBusinessAddress({});
      setMobileNumber("");
      setButtonStatus(false);
    });
  }

  return (
    <div style={{ flex: 1 }}>
      <HeaderBar />
      <div style={{ padding: 24 }}>
        <Form style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}>
          <Header as="h2" style={{ color: "white" }}>
            Add Outlet
          </Header>
          <Form.Field>
            <label style={{ color: "white" }}>Mobile Number</label>
            <Input labelPosition="left" type="text" placeholder="Discount">
              <Label basic>+65</Label>
              <input
                placeholder="Mobile Number"
                maxLength={8}
                value={mobileNumber}
                onChange={(e) =>
                  setMobileNumber(e.target.value.replace(/\D/, ""))
                }
              />
            </Input>
          </Form.Field>
          <Form.Field>
            <label style={{ color: "white" }}>Business Name</label>
            <input
              value={businessName}
              placeholder="Business Name"
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label style={{ color: "white" }}>Business Address</label>
            {/* <input
              value={businessAddress}
              placeholder="Business Address"
              onChange={(e) => setBusinessAddress(e.target.value)}
            /> */}
            <Autocomplete
              style={{ width: "100%" }}
              placeholder="Input Establishment Name"
              onPlaceSelected={(place) => {
                setBusinessAddress({
                  address: place.formatted_address,
                  name: place.name,
                  id: place.place_id,
                });
              }}
              types={["establishment"]}
              componentRestrictions={{ country: "sg" }}
              name="place_name"
              // value={this.state.place_name}
              fields={["formatted_address", "place_id", "name"]}
            />
          </Form.Field>
          <Form.Field style={{ flexDirection: "column", display: "flex" }}>
            <label style={{ color: "white" }}>Business Category</label>
            {/* <input placeholder="Last Name" /> */}
            <div
              style={{
                flexDirection: "row",
                flexGrow: 1,
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {categories.map((category, index) => {
                return (
                  <Category
                    category={category}
                    onPress={setBusinessCategory}
                    selected={businessCategory}
                  />
                );
              })}
            </div>
          </Form.Field>
          {/* <Form.Field>
          <Checkbox label="I agree to the Terms and Conditions" />
        </Form.Field> */}
          <Button
            disabled={buttonStatus}
            fluid
            secondary
            type="submit"
            onClick={() =>
              onClickAction({
                userID: claims.userID,
                businessAddress,
                businessName,
                mobileNumber,
              })
            }
          >
            {buttonStatus ? "Adding..." : "Submit"}
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default withAuthentication(AddCompany);
