/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Header,
  Radio,
  Input,
  Label,
  Image,
  Search,
} from "semantic-ui-react";
import HeaderBar from "../components/HeaderBar";
import fire from "../fire";
import { storage } from "firebase";
import {
  withFireAuthentication,
  withAuthentication,
} from "../HOC/withFirebase";
import _ from "lodash";
function AddPromo({ context }) {
  // //@ts-ignore

  const { user, claims } = context;

  const [promoName, setPromoName] = useState("");
  const [discount, setDiscount] = useState(0);
  const [initialPrice, setInitialPrice] = useState(0);

  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState<any>();
  const [imageURL, setImageURL] = useState<string>("");
  const [remoteURL, setRemoteURL] = useState<string>("");
  const [business, setBusiness] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(false);

  // const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const values = claims.companies?.map((company) => {
      return { id: company.id, title: company.businessName };
    });
    console.log("ehre");
    setSuggestions(values);
  }, [claims.companies]);

  function onSubmitPromo(data: any) {
    if (
      imageFile &&
      discount &&
      initialPrice &&
      description &&
      promoName &&
      business
    ) {
      // async magic goes here...
      setButtonStatus(true);
      const uploadTask = storage()
        .ref(`/images/${businessName}/${promoName}`)
        .put(imageFile);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          //catches the errors
          alert(err);
          setButtonStatus(false);
        },
        () => {
          console.log("asdas");
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage()
            .ref(`images/${businessName}`)
            .child(promoName)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              console.log(fireBaseUrl);
              // setRemoteURL(fireBaseUrl);
              // return fireBaseUrl;
              let uid = fire.auth().currentUser?.uid;
              const userRef = fire.firestore().collection("Promos").doc();
              // userRef.set(user);
              userRef.set({ ...data, isDeleted: false, image: fireBaseUrl });
              setPromoName("");
              setDiscount(0);
              setInitialPrice(0);
              setDescription("");
              setImageFile("");
              setImageURL("");
              setBusinessName("");
              setSuggestions([]);
              // setIsActive(false);
              setButtonStatus(false);
            });
        }
      );
    } else {
      alert("Form incomplete");
    }
  }

  const handleImageAsFile = (e: any) => {
    const imageAsFile = e.target.files[0];
    console.log(imageAsFile);
    setImageFile(imageAsFile);
    setImageURL(URL.createObjectURL(imageAsFile));
  };

  const handleResultSelect = (e, { result }) => {
    setBusiness(result.id);
    setBusinessName(result.title);
    console.log(result);
  };

  const handleSearchChange = (e, { value }) => {
    setLoading(true);
    setBusinessName(value);

    setTimeout(() => {
      if (businessName.length < 1) return;

      const re = new RegExp(_.escapeRegExp(businessName), "i");
      const isMatch = (result) => re.test(result.title);

      setLoading(false);
      setResults(_.filter(suggestions, isMatch));
    }, 300);
  };

  return (
    <div style={{ flex: 1 }}>
      <HeaderBar />
      <div style={{ padding: 24 }}>
        <Form style={{ width: 400, marginLeft: "auto", marginRight: "auto" }}>
          <Header as="h2" style={{ color: "white" }}>
            Add Promo
          </Header>
          <Form.Field>
            <label style={{ color: "white" }}>Promo Name</label>
            <input
              value={promoName}
              placeholder="Promo Name"
              onChange={(e) => setPromoName(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label style={{ color: "white" }}>Initial Price</label>
            <Input labelPosition="right" type="text" placeholder="Discount">
              <input
                value={initialPrice}
                onChange={(e) =>
                  e.target.value.trim().length > 0
                    ? setInitialPrice(
                        parseInt(e.target.value.replace(/\D/, ""))
                      )
                    : setInitialPrice(0)
                }
              />
              <Label basic>SGD</Label>
            </Input>
          </Form.Field>
          <Form.Field>
            <label style={{ color: "white" }}>Discount</label>
            <Input labelPosition="right" type="text" placeholder="Discount">
              <input
                max={99}
                value={discount}
                maxLength={2}
                onChange={(e) =>
                  e.target.value.trim().length > 0
                    ? setDiscount(parseInt(e.target.value.replace(/\D/, "")))
                    : setDiscount(0)
                }
              />
              <Label basic>%</Label>
            </Input>
          </Form.Field>
          <Form.Field>
            <label style={{ color: "white" }}>Final Price</label>
            <Input labelPosition="right" type="text" placeholder="Discount">
              <input
                value={initialPrice - (initialPrice * discount) / 100}
                disabled
                // onChange={(e) =>
                //   e.target.value.trim().length > 0
                //     ? setInitialPrice(
                //         parseInt(e.target.value.replace(/\D/, ""))
                //       )
                //     : setInitialPrice(0)
                // }
              />
              <Label basic>SGD</Label>
            </Input>
          </Form.Field>
          <Form.Field>
            <label style={{ color: "white" }}>Description</label>

            <textarea
              value={description}
              style={{ color: "black" }}
              placeholder={"Description"}
              onChange={(e) => setDescription(e.target.value)}
            >
              Description
            </textarea>
          </Form.Field>
          <Form.Field>
            <label style={{ color: "white" }}>Image</label>
            <Image
              src={
                remoteURL ||
                imageURL ||
                "https://react.semantic-ui.com/images/wireframe/image.png"
              }
              size="large"
            />
            <input accept="image/*" type="File" onChange={handleImageAsFile} />
          </Form.Field>
          <Form.Field>
            <label style={{ color: "white" }}>Business</label>
            <Search
              loading={loading}
              onResultSelect={handleResultSelect}
              onSearchChange={_.debounce(handleSearchChange, 500, {
                leading: true,
              })}
              results={results}
              value={businessName}
            />
          </Form.Field>
          {/* 
          <Form.Field>
            <Radio
              toggle
              style={{ color: "white" }}
              value={isActive ? 1 : 0}
              label={isActive ? "Active" : "Not Active"}
              onChange={() => setIsActive(!isActive)}
            />
          </Form.Field> */}
          <Button
            fluid
            secondary
            type="submit"
            onClick={() =>
              onSubmitPromo({
                promoName,
                discount,
                description,
                companyID: business,
                userID: claims.userID,
                // isActive,
                initialPrice,
              })
            }
          >
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default withAuthentication(AddPromo);
