import React, { useState, useEffect } from "react";
import fire from "../fire";
import { useHistory } from "react-router";
import { FirebaseError } from "firebase";

const Context = React.createContext({});

function FirebaseProvider({ children }: any) {
  const [user, setUser] = useState<any>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [firebase, setFirebase] = useState<typeof fire>(fire);
  const [claims, setClaims] = useState<any>({});
  const history = useHistory();

  //   componentDidMount() {
  //     const { auth } = this.state.firebase;
  //     auth.onAuthStateChanged(user => {
  //         if (user) {
  //             this.setState({ user });

  //             this.fetchCustomClaims();
  //         }
  //     });
  // }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setClaims(user);
        fetchCustomClaims();
        setLoaded(true);
      } else {
        setLoaded(true);
      }
    });
  }, [firebase, user]);

  function logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setClaims(null);
      });
    history.push("Login");
  }

  function fetchCustomClaims() {
    let user;
    const truc = fire
      .firestore()
      .collection("Users")
      .where("email", "==", fire.auth().currentUser?.email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          user = doc.data();
          console.log(doc.data());
          fire
            .firestore()
            .collection("Companies")
            .where("userID", "==", doc.ref.id)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((data) => {
                user = {
                  ...user,
                  userID: doc.ref.id,
                  companies: [
                    { ...user.companies, id: data.ref.id, ...data.data() },
                  ],
                };
                console.log(user);
                setClaims(user);
              });
              // this.applyStatus = true
            });
        });
      });
  }

  function login(email, password, setError) {
    fire
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        const user = result.user;
        setUser(user);
        history.push("Promos");

        fetchCustomClaims();
      })
      .catch((e: FirebaseError) => setError(e.message));
  }

  return (
    <Context.Provider
      value={{
        firebase,
        loaded,
        user,
        claims,
        login: login,
        logout: logout,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const PleaseLoginMessage = () => {
  // const history = useHistory();
  // history.push("Login");
  return <p>Redirecting ...</p>;
};

const withFirebase = (WrappedComponent) => {
  const WithHOC = (props) => {
    return (
      <Context.Consumer>
        {(context) => <WrappedComponent {...props} context={context} />}
      </Context.Consumer>
    );
  };
  WithHOC.WrappedComponent = WrappedComponent;

  return WithHOC;
};

const withAuthentication = (WrappedComponent) => {
  const WithHOC = (props) => {
    return (
      <Context.Consumer>
        {(context: any) =>
          context.loading ? (
            <WrappedComponent {...props} context={context} />
          ) : (
            <WrappedComponent {...props} context={context} />
          )
        }
      </Context.Consumer>
    );
  };
  WithHOC.WrappedComponent = WrappedComponent;

  return WithHOC;
};

const withFireAuthentication = (WrappedComponent) =>
  withFirebase(withAuthentication(WrappedComponent));

export {
  FirebaseProvider,
  Context,
  withFirebase,
  withAuthentication,
  withFireAuthentication,
};
