import React, { Component } from "react";
import Promo from "../Promo";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router";

function Announcement() {
  const history = useHistory();
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text" style={{ textAlign: "center" }}>
              <h2>Join us Now and Get Limited Exlusive Deals</h2>
              <h4>
                <span style={{ color: "#5ca499", fontWeight: "bolder" }}>
                  {" "}
                  IT'S COMPLETELY FREE
                </span>{" "}
              </h4>
              <Button
                onClick={() => history.push("/Register")}
                style={{
                  width: "20%",
                  background: "#5ca499",
                  color: "#fff",
                  fontSize: 16,
                  fontWeight: "bold",
                  marginBottom: 24,
                }}
                secondary
              >
                SIGN UP NOW
              </Button>
              <h3>
                <span style={{ color: "#5ca499", fontWeight: "bolder" }}>
                  GREEN COUPON
                </span>{" "}
                WILL NEVER BE AN EXPENSE TO YOUR BUSINESS
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcement;
