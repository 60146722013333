import React, { Component } from "react";
import { Image, Button } from "semantic-ui-react";
import * as logo from "../../assets/logo.png";
import { useHistory } from "react-router";

function Presentation() {
  const history = useHistory();

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h2 style={{ color: "white" }}>
                  Allow Your Business to Make Revenue in Any Situation
                  {/* <Image
                      src={logo}
                      style={{
                        width: "auto",
                        height: 40,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      size={"medium"}
                    /> */}
                </h2>
                {/* <p>Make Revenue in Any Situation</p> */}
                <Button
                  onClick={() => history.push("/Register")}
                  style={{
                    width: "40%",
                    background: "white",
                    color: "#5ca499",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                  secondary
                >
                  GET STARTED
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Presentation;
