import React, { Component } from "react";
import Promo from "../Promo";

export class About extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="about-text" style={{ textAlign: "center" }}>
                <h2>
                  ARE YOU LOOKING FOR SOLUTIONS TO SELL YOUR SERVICES ONLINE?
                </h2>

                <h4>
                  <span style={{ color: "#5ca499", fontWeight: "bolder" }}>
                    {" "}
                    Green Coupon
                  </span>{" "}
                  is the Solution for Your Business!
                </h4>
                <h3>
                  Get New Customers Now and Attract Your Usual Ones to Come Back
                  More Often
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
