import React from "react";
import { Image, Icon } from "semantic-ui-react";

export default function Category({ category, selected, onPress }) {
  const { image, name } = category;
  const isSelected = selected === name;
  return (
    <div
      onClick={() => onPress(name)}
      style={{
        cursor: "pointer",
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "32%",
        height: 110,
        justifyContent: "space-between",
        padding: 18,
        backgroundColor: "white",
        borderColor: isSelected ? "#5166ff" : "white",
        borderWidth: 2,
        borderStyle: "solid",
        borderRadius: 16,
        margin: 3,
      }}
    >
      {isSelected && (
        <Icon
          name="check circle"
          size="small"
          style={{ position: "absolute", right: 0, color: "#5166ff", top: 5 }}
        />
      )}
      <Image src={image} style={{ width: 50, height: 50 }} />
      <p
        style={{
          color: "black",
          width: "100%",
          textAlign: "center",
        }}
      >
        {name}
      </p>
    </div>
  );
}
