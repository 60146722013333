import React, { useState, useEffect } from "react";
import { Button, Form, Image, Header, Icon } from "semantic-ui-react";
import * as logo from "../assets/logo.png";
// import fire from "../fire";
import { useHistory } from "react-router";
import { withFireAuthentication, withFirebase } from "../HOC/withFirebase";

function Login({ context }) {
  const { login, logout, user } = context;

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const [password, setPassword] = useState("");
  const history = useHistory();
  useEffect(() => {
    if (user) {
      history.push("/orders");
    }
  }, [history, user]);

  function onClickAction() {
    login(email, password, setError);
  }

  return (
    <div
      style={{
        padding: 24,

        display: "flex",
        flex: 1,
      }}
    >
      <div onClick={() => history.goBack()}>
        <Icon
          name="arrow left"
          size="huge"
          style={{
            position: "absolute",
            left: 24,
            color: "white",
            top: 24,
          }}
        />
      </div>
      <Form style={{ alignSelf: "center", margin: "auto" }}>
        <Image
          src={logo}
          style={{ width: "auto", height: 30, marginBottom: 80 }}
          size={"medium"}
        />
        {error && <label style={{ color: "black" }}>{error}</label>}

        <Form.Field>
          <label style={{ color: "white" }}>Email</label>
          <input
            style={{
              borderColor: error ? "red" : "white",
              borderWidth: 1,
              borderStyle: "solid",
            }}
            placeholder="test@provider.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label style={{ color: "white" }}>Password</label>
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </Form.Field>
        <Button fluid secondary type="submit" onClick={() => onClickAction()}>
          Login
        </Button>
      </Form>
    </div>
  );
}

export default withFireAuthentication(Login);
