import React from "react";
import Promo from "../Promo";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router";
import * as moisturizer from "../../assets/moisturizer.jpg";

function VoucherDisplay() {
  return (
    <div id="features">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{}}>Customize your own vouchers in less than 2min</h2>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="col-xs-12 col-md-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Promo
              promo={{
                image:
                  "https://firebasestorage.googleapis.com/v0/b/lockdowncoupons.appspot.com/o/images%2Fperson-shaving-a-man-s-face-with-straight-razor-897251.jpg?alt=media&token=306b69c1-fc99-4b32-82fe-0f3362ec8764",
                promoName: "Shave + Haircut",
                initialPrice: 90,
                discount: 20,
                description:
                  "In such catastrophic times, Gentlemen's Barbershop would like to Thank You for your support and as a gesture of our appreciation, we'd like to offer a 20% discount on this voucher worth 90$! This coupon has no expiry date. You can use it anytime once we open. We're waiting for you!",
              }}
              fake
              buy={true}
            />
          </div>
          <div
            className="col-xs-12 col-md-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Promo
              promo={{
                image:
                  "https://firebasestorage.googleapis.com/v0/b/lockdowncoupons.appspot.com/o/images%2Fperson-washing-woman-s-hair-3993449.jpg?alt=media&token=00eab7f3-abdd-4cc6-844e-da729aaf18d0",
                promoName: "Gift Card - 100$ + Wash & Blow Dry",
                initialPrice: 100,
                discount: 0,
                description:
                  "This 100$ Gift Card will entitle you a 100$ Credit at Rosie Beauty Salon + Wash and Blow Dry. This gift card is not convertible to cash and have no expiry date.",
              }}
              fake
              buy={true}
            />
          </div>
          <div
            className="col-xs-12 col-md-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Promo
              promo={{
                image:
                  "https://firebasestorage.googleapis.com/v0/b/lockdowncoupons.appspot.com/o/images%2Fassorted-beauty-blur-business-457702.jpg?alt=media&token=b7a64485-4639-4ca3-95a5-fbd400d25d6a",
                promoName: "Makeup Workshop",
                initialPrice: 100,
                discount: 0,
                description:
                  "Dear customer, Thank You for your support and as a gesture of our appreciation, we'd like to offer this professional Makeup Workshop with our specialist Lauren.See you at the workshop!",
              }}
              fake
              buy={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoucherDisplay;
