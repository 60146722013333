import React, { useState, useEffect } from "react";
import fire from "../fire";
import Promo from "../components/Promo";
import HeaderBar from "../components/HeaderBar";
import withUser from "../HOC/withUser";
import { withAuthentication } from "../HOC/withFirebase";

function MyPromos({ context }) {
  const { claims } = context;
  const [promos, setPromos] = useState<Array<any>>([]);

  useEffect(() => {
    fire
      .firestore()
      .collection("Promos")
      .where("isDeleted", "==", false)
      .where("userID", "==", claims.userID || "sdad")
      .get()
      .then(function (querySnapshot) {
        return querySnapshot.docs.map((doc) => {
          setPromos((prevPromos: any) =>
            prevPromos.concat({ id: doc.id, ...doc.data() })
          );
        });
      });
  }, [claims.userID]);

  return (
    <div style={{ flex: 1 }}>
      <HeaderBar />
      <div
        style={{
          padding: 48,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {promos.map((promo, index) => {
          return <Promo promo={promo} key={index} remove />;
        })}
      </div>
    </div>
  );
}

export default withAuthentication(MyPromos);
