import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Label,
  Header,
  Image,
  Icon,
} from "semantic-ui-react";
//@ts-ignore
import Autocomplete from "react-google-autocomplete";

import fire from "../fire";
import HeaderBar from "../components/HeaderBar";
import Category from "../components/Category";
import * as logo from "../assets/logo.png";
import { useHistory } from "react-router";

const categories = require("../assets/categories.json");

function AddCompany() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState({});
  const [businessCategory, setBusinessCategory] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  function onClickAction(email, password, user: any, business: any) {
    setButtonStatus(true);
    fire
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((ok) => {
        console.log(ok);
        const companyRef = fire.firestore().collection("Companies").doc();
        const userRef = fire.firestore().collection("Users").doc();
        userRef.set({ ...user, companyID: [companyRef.id] });
        companyRef
          .set({ ...business, userID: userRef.id })
          .then((done) => console.log(done));
        history.push("/orders");
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(errorMessage);
        setButtonStatus(false);

        return;
      });
  }

  return (
    <div style={{ flex: 1, display: "flex", padding: 24 }}>
      <div onClick={() => history.goBack()}>
        <Icon
          name="arrow left"
          size="huge"
          style={{
            position: "absolute",
            left: 24,
            color: "white",
            top: 24,
          }}
        />
      </div>
      <Form
        style={{
          width: 500,
          marginLeft: "auto",
          marginRight: "auto",
          alignSelf: "center",
        }}
      >
        <Image
          src={logo}
          style={{ width: "auto", height: 30, marginBottom: 80 }}
          size={"medium"}
        />
        <Header as="h2" style={{ color: "white" }}>
          Become a Green coupon Partner !
        </Header>
        <Form.Field>
          <label style={{ color: "white" }}>Full Name</label>
          <input
            placeholder="Full Name"
            onChange={(e) => setFullName(e.target.value)}
            value={fullName}
          />
        </Form.Field>
        <Form.Field>
          <label style={{ color: "white" }}>Email</label>
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Form.Field>
        <Form.Field>
          <label style={{ color: "white" }}>Password</label>
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </Form.Field>

        <Form.Field>
          <label style={{ color: "white" }}>Mobile Number</label>
          <Input labelPosition="left" type="text" placeholder="Discount">
            <Label basic>+65</Label>
            <input
              placeholder="Mobile Number"
              maxLength={8}
              value={mobileNumber}
              onChange={(e) =>
                setMobileNumber(e.target.value.replace(/\D/, ""))
              }
            />
          </Input>
        </Form.Field>
        <Form.Field>
          <label style={{ color: "white" }}>Business Name</label>
          <input
            value={businessName}
            placeholder="Business Name"
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label style={{ color: "white" }}>Business Address</label>
          {/* <input
              value={businessAddress}
              placeholder="Business Address"
              onChange={(e) => setBusinessAddress(e.target.value)}
            /> */}
          <Autocomplete
            style={{ width: "100%" }}
            placeholder="Input Establishment Name"
            onPlaceSelected={(place) => {
              setBusinessAddress({
                address: place.formatted_address,
                name: place.name,
                id: place.place_id,
              });
            }}
            types={["establishment"]}
            componentRestrictions={{ country: "sg" }}
            name="place_name"
            // value={this.state.place_name}
            fields={["formatted_address", "place_id", "name"]}
          />
        </Form.Field>
        <Form.Field style={{ flexDirection: "column", display: "flex" }}>
          <label style={{ color: "white" }}>Business Category</label>
          {/* <input placeholder="Last Name" /> */}
          <div
            style={{
              flexDirection: "row",
              flexGrow: 1,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {categories.map((category, index) => {
              return (
                <Category
                  category={category}
                  onPress={setBusinessCategory}
                  selected={businessCategory}
                />
              );
            })}
          </div>
        </Form.Field>
        {/* <Form.Field>
          <Checkbox label="I agree to the Terms and Conditions" />
        </Form.Field> */}
        <Button
          disabled={buttonStatus}
          fluid
          secondary
          type="submit"
          onClick={() =>
            onClickAction(
              email,
              password,
              {
                fullName,
                email,
              },
              { businessAddress, businessName, mobileNumber }
            )
          }
        >
          {buttonStatus ? "Registering..." : "Submit"}
        </Button>
      </Form>
    </div>
  );
}

export default AddCompany;
