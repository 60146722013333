//@ts-nocheck

import React, { Component } from "react";
import { Menu } from "semantic-ui-react";
import logo from "../assets/logo.png";
import { useHistory } from "react-router";

import { withFireAuthentication } from "../HOC/withFirebase";

function HeaderBar({ context }) {
  const { user, claims, logout } = context;
  const history = useHistory();
  const activeItem = history.location.pathname.toLocaleLowerCase();
  return (
    <Menu stackable style={{ background: "transparent" }}>
      <Menu.Item style={{ color: "white", padding: 24 }}>
        <img src={logo} alt="logo" style={{ width: 200 }} />
      </Menu.Item>

      <Menu.Item
        style={{ color: "white" }}
        name="orders"
        active={activeItem === "/Orders"}
        onClick={() => history.push("/Orders")}
      >
        Orders
      </Menu.Item>

      <Menu.Item
        style={{ color: "white" }}
        name="features"
        active={activeItem === "/AddPromo"}
        onClick={() => history.push("/AddPromo")}
      >
        Add Promo
      </Menu.Item>

      <Menu.Item
        style={{ color: "white" }}
        name="testimonials"
        active={activeItem === "/testimonials"}
        onClick={() => history.push("/AddCompany")}
      >
        Add Outlet
      </Menu.Item>
      <Menu.Item
        style={{ color: "white" }}
        name="my-promos"
        active={activeItem === "/MyPromos"}
        onClick={() => history.push("/MyPromos")}
      >
        My Coupons
      </Menu.Item>
      {claims.isAdmin && (
        <>
          <Menu.Item
            style={{ color: "white" }}
            name="sign-in"
            active={activeItem === "/sign-in"}
            onClick={() => history.push("/AllPromos")}
          >
            All Coupons
          </Menu.Item>
          <Menu.Item
            style={{ color: "white" }}
            name="sign-in"
            active={activeItem === "/sign-in"}
            onClick={() => history.push("/Companies")}
          >
            Companies
          </Menu.Item>
        </>
      )}

      <Menu.Item
        style={{ color: "white", marginLeft: "auto" }}
        name="Loading"
        onClick={() => history.push("/Companies")}
      >
        {claims.fullName && (
          <p
            style={{
              alignSelf: "center",
              color: "white",
              fontSize: 18,
              fontWeight: "bold",
              marginRight: 12,
            }}
          >
            Welcome {claims.fullName} !
          </p>
        )}
      </Menu.Item>
      <Menu.Item style={{ color: "white" }} onClick={logout}>
        <p
          style={{
            alignSelf: "center",
            color: "white",
            fontSize: 18,
            fontWeight: "bold",
            marginRight: 24,
          }}
        >
          Logout
        </p>
      </Menu.Item>
    </Menu>
  );
}

export default withFireAuthentication(HeaderBar);
